/*eslint no-lone-blocks: "off"*/
{/* BLOG ONE /////////////////////////////////////////////////////////////////////////////*/ }

const blogOne = `
Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa hic cupiditate dolores eius?
Itaque et totam ducimus. Commodi saepe, adipisci harum explicabo placeat molestias! Reiciendis
voluptas delectus eaque id?

Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa hic cupiditate dolores eius?
Itaque et totam ducimus. Commodi saepe, adipisci harum explicabo placeat molestias! Reiciendis
voluptas delectus eaque id?
Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa hic cupiditate dolores eius?
Itaque et totam ducimus. Commodi saepe, adipisci harum explicabo placeat molestias! Reiciendis
voluptas delectus eaque id?

`

{/* BLOG TWO /////////////////////////////////////////////////////////////////////////////*/ }

const blogTwo = `
Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae ipsa hic cupiditate dolores eius?
Itaque et totam ducimus. Commodi saepe, adipisci harum explicabo placeat molestias! Reiciendis
voluptas delectus eaque id?
`
{/* EXPORTS /////////////////////////////////////////////////////////////////////////////*/ }

module.exports = {
  blogOne: blogOne,
  blogTwo: blogTwo
}