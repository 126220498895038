module.exports = {
  DRAGGABLE_GIT: require('./gallery/projects/DRAGGABLE-GIT.jpg'),
  DRAGGABLE_NPM: require('./gallery/projects/DRAGGABLE-NPM.jpg'),
  DRAGGABLE_VISUAL: require('./gallery/projects/DRAGGABLE-VISUAL.gif'),
  CASHFLOW_GREETING: require('./gallery/projects/CASHFLOW-GREETING.jpg'),
  CASHFLOW_DATASHEET: require('./gallery/projects/CASHFLOW-DATASHEET.jpg'),
  CASHFLOW_NEWBUDGET: require('./gallery/projects/CASHFLOW-NEWBUDGET.jpg'),
  CASHFLOW_DELETE: require('./gallery/projects/CASHFLOW-DELETE.jpg'),
  KPCON_INTRO: require('./gallery/projects/KPCON-INTRO.jpg'),
  KPCON_DETAIL: require('./gallery/projects/KPCON-DETAIL.jpg'),
  KPCON_GALLERY: require('./gallery/projects/KPCON-GALLERY.jpg'),
  KPCON_EMAIL: require('./gallery/projects/KPCON-EMAIL.jpg'),
  KPCON_HTML: require('./gallery/projects/KPCON-HTML.jpg'),
  KPCON_SERVER: require('./gallery/projects/KPCON-SERVER.jpg'),
  KPCON_SHOWCASE: require('./gallery/projects/KPCON-SHOWCASE.jpg'),
  SQLIFTING_GREETING: require('./gallery/projects/SQLIFTING-GREETING.jpg'),
  SQLIFTING_MANAGER: require('./gallery/projects/SQLIFTING-MANAGER.jpg'),
  SQLIFTING_EXBUILDER: require('./gallery/projects/SQLIFTING-EXBUILDER.jpg'),
  SQLIFTING_WOBUILDER: require('./gallery/projects/SQLIFTING-WOBUILDER.jpg'),
  SQLIFTING_INPROGRESS: require('./gallery/projects/SQLIFTING-INPROGRESS.jpg'),
  SQLIFTING_SHOWCASE: require('./gallery/projects/SQLIFTING-SHOWCASE.jpg'),
  SNEKKEL_GREETING: require('./gallery/projects/SNEKKEL-GREETING.jpg'),
  SNEKKEL_SPRITE: require('./gallery/projects/SNEKKEL-SPRITE.jpg'),
  SNEKKEL_DIFFICULTY: require('./gallery/projects/SNEKKEL-DIFFICULTY.jpg'),
  SNEKKEL_SHOWCASE: require('./gallery/projects/SNEKKEL-SHOWCASE.jpg'),
  GODSPEED_HOME: require('./gallery/projects/GODSPEED-HOME.jpg'),
  GODSPEED_BUTTON: require('./gallery/projects/GODSPEED-BUTTON.jpg'),
  GODSPEED_INPUT: require('./gallery/projects/GODSPEED-INPUT.jpg'),
  GODSPEED_MODAL: require('./gallery/projects/GODSPEED-MODAL.jpg'),
  GODSPEED_SHOWCASE: require('./gallery/projects/GODSPEED-SHOWCASE.jpg'),
  DISARRAY_GREETING: require('./gallery/projects/DISARRAY-GREETING.jpg'),
  DISARRAY_START: require('./gallery/projects/DISARRAY-START.jpg'),
  DISARRAY_SOLVED: require('./gallery/projects/DISARRAY-SOLVED.jpg'),
  DISARRAY_LOGIC: require('./gallery/projects/DISARRAY-LOGIC.jpg'),
  DISARRAY_LEADERBOARD: require('./gallery/projects/DISARRAY-LEADERBOARD.jpg'),
  DISARRAY_SHOWCASE: require('./gallery/projects/DISARRAY-SHOWCASE.jpg'),
  PORTFOLIO_HOME: require('./gallery/projects/PORTFOLIO-HOME.jpg'),
  PORTFOLIO_ABOUT: require('./gallery/projects/PORTFOLIO-ABOUT.jpg'),
  PORTFOLIO_PROJECTS: require('./gallery/projects/PORTFOLIO-PROJECTS.jpg'),
  PORTFOLIO_PROJECT: require('./gallery/projects/PORTFOLIO-PROJECT.jpg'),
  PORTFOLIO_RESUME: require('./gallery/projects/PORTFOLIO-RESUME.jpg'),
  PORTFOLIO_SHOWCASE: require('./gallery/projects/PORTFOLIO-SHOWCASE.jpg'),
  MTMUSIC_GOOGLE: require('./gallery/projects/MTM-GOOGLE.jpg'),
  MTMUSIC_PLAYER: require('./gallery/projects/MTM-PLAYER.jpg'),
  MTMUSIC_PLAYLISTS: require('./gallery/projects/MTM-PLAYLISTS.jpg'),
  MTMUSIC_SHOWCASE: require('./gallery/projects/MTM-SHOWCASE.jpg'),
  NEETCHAT_HOME: require('./gallery/projects/NEETCHAT-HOME.jpg'),
  NEETCHAT_CHAT: require('./gallery/projects/NEETCHAT-CHAT.jpg'),
  ARTE_BELLA_HOME: require('./gallery/projects/ARTE-BELLA-HOME.jpg'),
  ARTE_BELLA_SHOWCASE: require('./gallery/projects/ARTE-BELLA-SHOWCASE.jpg'),
  TOOKFEED_HOME: require('./gallery/projects/TOOKFEED-HOME.jpg'),
  TOOKFEED_REGISTER: require('./gallery/projects/TOOKFEED-REGISTER.jpg'),
  TOOKFEED_PROFILE: require('./gallery/projects/TOOKFEED-PROFILE.jpg'),
  TOOKFEED_OPTIONS: require('./gallery/projects/TOOKFEED-OPTIONS.jpg'),
  TOOKFEED_SERVER: require('./gallery/projects/TOOKFEED-SERVER.jpg'),
  TOOKFEED_SHOWCASE: require('./gallery/projects/TOOKFEED-SHOWCASE.jpg'),
  THEMESFK_HOME: require('./gallery/projects/TFK-HOME.jpg'),
  THEMESFK_CODE: require('./gallery/projects/TFK-CODE.jpg'),
  THEMESFK_PREV1: require('./gallery/projects/TFK-PREV1.jpg'),
  THEMESFK_PREV2: require('./gallery/projects/TFK-PREV2.jpg'),
  THEMESFK_PREV3: require('./gallery/projects/TFK-PREV3.jpg'),
}